<template>
  <vs-card class="emailCard">
    <!-- <div slot="header">
      <feather-icon icon="ChevronLeftIcon" svgClasses="h-4 w-4" class="mr-2 cursor-pointer" @click="$router.push({name: 'email-template'})"></feather-icon>
      <vs-label style="font-weight: bold">Email List</vs-label>
    </div> -->
    <vs-form>
      <vs-label class="label">Subject Line</vs-label>
      <vs-input
        v-validate="'required|min:3|max:200'"
        class="col-sm-3"
        data-vv-validate-on="blur"
        name="subject"
        v-model="pages.subject"
      >
      </vs-input>
      <span class="error">{{ errors.first("subject") }}</span>
    </vs-form>
    <div class="editEmail">
      <vs-label class="label">Email Content</vs-label>
    </div>
    <vs-label class="label">Available Linked Content</vs-label>
    <div class="linkedContent">
      <ul>
        <li class="keywords" v-for="item in keywords" :key="item"><span>{{item}}</span></li>
      </ul>
    </div>
    <div class="editEmail">
      <vs-label class="label"> Body Content</vs-label>
      <quillEditor :pages="pages"></quillEditor>
    </div>
    <vs-row class="flex flex-wrap items-center p-6">
      <vs-button
        class="mr-6"
        color="danger"
        type="flat"
        @click="$router.replace('/admin/email-template')"
        >Cancel</vs-button
      >
      <vs-button @click="saveEmailDetails" align="left">Save Details</vs-button>
    </vs-row>
    <!-- <div slot="footer" class="footer">
      <vs-row>
        <vs-button @click="saveEmailDetails" align="left"
          >Save Details</vs-button
        >
      </vs-row> 
    </div> -->
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import quillEditor from "../../Editor";
import moment from "moment";
import { emailTemplateMixin } from '../../../mixins/emailTemplateMixin'

export default {
  name: "emailViewEditDetail",
  components: {
    quillEditor,
  },
  mixins: [emailTemplateMixin],
  data: () => ({
    pages: {
      content: "",
      subject: "",
    },
    keywords:[]
  }),
  methods: {
    getEmailDetail(id) {
      let self = this;
      this.getEmailTemplateDetail(id).then((res) => {
        this.pages.content = res.emailTemplate.content;
        this.pages.subject = res.emailTemplate.subject;
        this.pages = res.emailTemplate;
        this.keywords = res.emailTemplate.keywords
      });
    },
    saveEmailDetails() {
      this.$validator.validateAll().then((success) => {
        if (!success) {
          return false;
        }
        let info = {
          content: this.pages.content,
          subject: this.pages.subject,
          _id: this.$route.params.id
        }
        this.updateEmailTemplate(info ).then((res) => {
          this.$vs.notify({
            subject: "Update Email Template",
            text: res.message.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
        });
      });
    },
  },
  created() {
    let pageId = this.$route.params.id;
    this.getEmailDetail(pageId);
  },
};
</script>
<style>
.footer {
  padding-top: 40px;
  clear: both;
}
.error {
  color: red;
}
.ql-editor {
  min-height: 200px;
}
ul {
  list-style: none;
  margin-left: 0px;
}
.keywords {
  display: inline-block;
  border: 1px solid dimgrey;
  margin-right: 3px;
  padding: 5px;
  color: white;
  background-color: #3dc9b3;
}
.label {
  font-size: small;
  color: black;
}
.editEmail {
  padding-top: 20px;
  padding-bottom: 20px;
}
.emailCard {
  min-height: 650px;
  padding-left: 20px;
}
</style>
