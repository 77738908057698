import { gql } from "apollo-boost"

export const emailTemplateMixin = {
    methods:{
        getEmailTemplates(options){
            const getEmailTemplatesForAdmin = gql`
            query getEmailTemplatesForAdmin($options: PaginationInput!){
                getEmailTemplatesForAdmin(options: $options){
                    emailTemplates{
                        _id
                        subject
                        slug
                    }
                    pagination{
                        total
                        page
                    }
                }
            }
            `;
            return this.$apollo.query({
                query: getEmailTemplatesForAdmin,
                variables:{
                    options
                },
                fetchPolicy: 'network-only'

            }).then(response => {
                return response.data.getEmailTemplatesForAdmin
            })
        },
        getEmailTemplateDetail(id){
            const getEmailTemplateDetailForAdmin = gql`
            query getEmailTemplateDetailForAdmin($id: ID!){
                getEmailTemplateDetailForAdmin(_id: $id){
                    emailTemplate{
                        _id
                        subject
                        content
                        keywords
                    }
                }
            }
            `;
            return this.$apollo.query({
                query: getEmailTemplateDetailForAdmin,
                variables:{
                    id
                },
                fetchPolicy: 'network-only'
            }).then(response => {
                return response.data.getEmailTemplateDetailForAdmin
            }).catch( err => {
                console.log(err)
            })
        },
        updateEmailTemplate(emailTemplateData){
            const saveEmailTemplatesForAdmin = gql`
            mutation saveEmailTemplatesForAdmin($emailTemplateData: EmailTemplateInput!){
                saveEmailTemplatesForAdmin(request: $emailTemplateData){
                    message{
                        message
                        code
                    }
                }
            }
            `;
            return this.$apollo.mutate({
                mutation: saveEmailTemplatesForAdmin,
                variables:{
                    emailTemplateData
                }
            }).then(response => {
                return response.data.saveEmailTemplatesForAdmin
            })
        }
    }
}
